import useBreakpoints from "@vertikal/e-prospera.hooks.use-breakpoints";
import { AlertIcon } from "@vertikal/e-prospera.ui.alert";
import { Angles } from "@vertikal/e-prospera.ui.angles";
import Box from "@vertikal/e-prospera.ui.box";
import Button from "@vertikal/e-prospera.ui.button";
import { List, ListItem } from "@vertikal/e-prospera.ui.list";
import Text from "@vertikal/e-prospera.ui.text";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Helmet from "react-helmet";
import { Trans, useTranslation } from "react-i18next";
import { Breadcrumbs } from "../components/Breadcrumbs/Breadcrumbs";
import DottedDivider from "../components/DottedDivider/DottedDivider";
import GlobalSettings from "../components/GlobalSettings";
import MainMenu from "../components/MainMenu/MainMenu";
import {
  AccentBlock,
  Advantages,
  BecomeResident,
  BecomeResidentGrid,
  BecomeResidentItem,
  BusinessBenefitsDottedDividerWrapper,
  BusinessBenefitsGrid,
  BusinessBenefitsItem,
  ContentWrapper,
  Dollars,
  HeroDottedDividerWrapper,
  LearnMoreButton,
  RegisterUpsell,
  RegisterUpsellItem,
  ResidencyHeadInfo,
  ResidencyType,
  ResidencyTypes,
  SuperScript,
} from "../styles/residency.styles";

const Residency = () => {
  const breakpoints = useBreakpoints();
  const [t] = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t("public.residency.title")}</title>
        <meta name="title" content={t("public.residency.meta.title")} />
        <meta
          name="description"
          content={t("public.residency.meta.description")}
        />
        <link rel="canonical" href="/residency" />
      </Helmet>
      <GlobalSettings />
      <MainMenu darkFooter>
        <AccentBlock paddingTop={1} paddingBottom={3} $color="success">
          <ContentWrapper $wide>
            <Breadcrumbs theme="dark">
              <Link to="/">
                <Trans>common.home</Trans>
              </Link>
              <span>
                <Trans>common.residency</Trans>
              </span>
            </Breadcrumbs>
          </ContentWrapper>
          <ContentWrapper>
            <ResidencyHeadInfo>
              <Box>
                <Text variant="h2" as="h1">
                  <Trans>public.residency.hero.title</Trans>
                </Text>
              </Box>
              <HeroDottedDividerWrapper>
                <DottedDivider color="success" />
              </HeroDottedDividerWrapper>
              <Box style={{ maxWidth: "32.5rem" }}>
                <Text variant="p-sm">
                  <Trans>public.residency.hero.subtitle</Trans>
                </Text>
              </Box>
            </ResidencyHeadInfo>
          </ContentWrapper>
        </AccentBlock>
        <Angles noLight invertColors flipVertical />
        <AccentBlock paddingBottom={5} paddingTop={4}>
          <ContentWrapper>
            <Box marginBottom={4}>
              <Text variant="h3" as="h2">
                <Trans>public.residency.why_register.title</Trans>
              </Text>
            </Box>
            <RegisterUpsell>
              <RegisterUpsellItem>
                <AlertIcon variant="success" size="small" />
                <Text>
                  <Trans>public.residency.why_register.point_1</Trans>
                </Text>
              </RegisterUpsellItem>
              <RegisterUpsellItem>
                <AlertIcon variant="success" size="small" />
                <Text>
                  <Trans>public.residency.why_register.point_2</Trans>
                </Text>
              </RegisterUpsellItem>
              <RegisterUpsellItem>
                <AlertIcon variant="success" size="small" />
                <Text>
                  <Trans>public.residency.why_register.point_3</Trans>
                </Text>
              </RegisterUpsellItem>
              <RegisterUpsellItem>
                <AlertIcon variant="success" size="small" />
                <Text>
                  <Trans>public.residency.why_register.point_4</Trans>
                </Text>
              </RegisterUpsellItem>
            </RegisterUpsell>
            <Box
              marginTop={4}
              style={{ gap: "1.5rem" }}
              display="flex"
              flexDirection="column"
              alignItems="center"
              width="100%"
            >
              <Box
                style={{ width: breakpoints.greaterThan.md ? "33%" : "100%" }}
              >
                <DottedDivider color="success" />
              </Box>
              <Text variant="h4" as="h2">
                <Trans>public.residency.why_register.cta</Trans>
              </Text>
            </Box>
          </ContentWrapper>
        </AccentBlock>
        <Angles flipVertical />
        <AccentBlock $color="accent" paddingTop={4.625} paddingBottom={5}>
          <ContentWrapper>
            <Box
              textAlign="center"
              display="flex"
              justifyContent="center"
              style={{ color: "#fff" }}
              marginBottom={3}
            >
              <Text variant="h3" as="h2">
                <Trans>public.residency.residency_types.title</Trans>
              </Text>
            </Box>
            <ResidencyTypes>
              <ResidencyType>
                <Box marginBottom={2}>
                  <StaticImage
                    width={432}
                    height={225}
                    placeholder="tracedSVG"
                    src="../images/illustrations/permit-card-dark.svg"
                    alt=""
                  />
                </Box>
                <Box marginBottom={1.5}>
                  <Text variant="h4" as="h3">
                    <Trans>public.residency.residency_types.list1.title</Trans>
                  </Text>
                </Box>
                <Box marginBottom={1.25}>
                  <Text color="gray">
                    <Trans>
                      public.residency.residency_types.list1.subtitle
                    </Trans>
                  </Text>
                </Box>
                <Box marginBottom={1.25}>
                  <List collapsed variant="checklist">
                    <ListItem>
                      <Trans>
                        public.residency.residency_types.list1.bullet_1
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        public.residency.residency_types.list1.bullet_2
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        public.residency.residency_types.list1.bullet_3
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        public.residency.residency_types.list1.bullet_4
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        public.residency.residency_types.list1.bullet_5
                      </Trans>
                    </ListItem>
                  </List>
                </Box>
                <Box>
                  <Dollars variant="h3">
                    $130{" "}
                    <SuperScript>
                      / <Trans>common.year</Trans>
                    </SuperScript>
                  </Dollars>
                </Box>
              </ResidencyType>
              <DottedDivider
                mobile="horizontal"
                direction="vertical"
                color="accent"
              />
              <ResidencyType>
                <Box marginBottom={2}>
                  <StaticImage
                    width={432}
                    height={225}
                    placeholder="tracedSVG"
                    src="../images/illustrations/physical-residency-card.svg"
                    alt=""
                  />
                </Box>
                <Box marginBottom={1.5}>
                  <Text variant="h4" as="h3">
                    <Trans>public.residency.residency_types.list2.title</Trans>
                  </Text>
                </Box>
                <Box marginBottom={1.25}>
                  <Text color="gray">
                    <Trans>
                      public.residency.residency_types.list2.subtitle
                    </Trans>
                  </Text>
                </Box>
                <Box marginBottom={1.25}>
                  <List collapsed variant="checklist">
                    <ListItem>
                      <Trans>
                        public.residency.residency_types.list2.bullet_1
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        public.residency.residency_types.list2.bullet_2
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        public.residency.residency_types.list2.bullet_3
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        public.residency.residency_types.list2.bullet_4
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        public.residency.residency_types.list2.bullet_5
                      </Trans>
                    </ListItem>
                  </List>
                </Box>
                <Box display="flex" width="100%">
                  <Box width="100%">
                    <Text variant="p-sm">
                      <Trans>
                        public.residency.residency_types.list2.honduran_citizens
                      </Trans>
                    </Text>
                    <Dollars variant="h3">
                      $260{" "}
                      <SuperScript>
                        / <Trans>common.year</Trans>
                      </SuperScript>
                    </Dollars>
                  </Box>
                  <Box width="100%">
                    <Text variant="p-sm">
                      <Trans>
                        public.residency.residency_types.list2.non_honduran_citizens
                      </Trans>
                    </Text>
                    <Dollars variant="h3">
                      $1300{" "}
                      <SuperScript>
                        / <Trans>common.year</Trans>
                      </SuperScript>
                    </Dollars>
                  </Box>
                </Box>
              </ResidencyType>
            </ResidencyTypes>
            <BecomeResident>
              <Box marginBottom={4}>
                <Text variant="h3">
                  <Trans>public.residency.not_resident_yet.title</Trans>
                </Text>
              </Box>
              <BecomeResidentGrid>
                <BecomeResidentItem>
                  <Box marginBottom={1}>
                    <StaticImage
                      width={72}
                      height={72}
                      alt=""
                      src="../images/duotone-icons/man.svg"
                    ></StaticImage>
                  </Box>
                  <Text variant="h5" as="h4">
                    <Trans>
                      public.residency.not_resident_yet.card_1.title
                    </Trans>
                  </Text>
                  <Text variant="p-sm">
                    <Trans>public.residency.not_resident_yet.card_1.text</Trans>
                  </Text>
                </BecomeResidentItem>
                <BecomeResidentItem>
                  <Box marginBottom={1}>
                    <StaticImage
                      width={72}
                      height={72}
                      alt=""
                      src="../images/duotone-icons/application.svg"
                    ></StaticImage>
                  </Box>
                  <Text variant="h5" as="h4">
                    <Trans>
                      public.residency.not_resident_yet.card_2.title
                    </Trans>
                  </Text>
                  <Text variant="p-sm">
                    <Trans>public.residency.not_resident_yet.card_2.text</Trans>
                  </Text>
                </BecomeResidentItem>
                <BecomeResidentItem>
                  <Box marginBottom={1}>
                    <StaticImage
                      width={72}
                      height={72}
                      alt=""
                      src="../images/duotone-icons/sandclock.svg"
                    ></StaticImage>
                  </Box>
                  <Text variant="h5" as="h4">
                    <Trans>
                      public.residency.not_resident_yet.card_3.title
                    </Trans>
                  </Text>
                  <Text variant="p-sm">
                    <Trans>public.residency.not_resident_yet.card_3.text</Trans>
                  </Text>
                </BecomeResidentItem>
                <BecomeResidentItem>
                  <Box marginBottom={1}>
                    <StaticImage
                      width={72}
                      height={72}
                      alt=""
                      src="../images/duotone-icons/flag.svg"
                    ></StaticImage>
                  </Box>
                  <Text variant="h5" as="h4">
                    <Trans>
                      public.residency.not_resident_yet.card_4.title
                    </Trans>
                  </Text>
                  <Text variant="p-sm">
                    <Trans>public.residency.not_resident_yet.card_4.text</Trans>
                  </Text>
                </BecomeResidentItem>
              </BecomeResidentGrid>
              <Button href={process.env.GATSBY_REDIRECT_URL} color="main">
                <Trans>public.residency.not_resident_yet.cta</Trans>
              </Button>
            </BecomeResident>
          </ContentWrapper>
        </AccentBlock>
        <Angles flipHorizontal />
        <AccentBlock paddingTop={4} paddingBottom={4}>
          <ContentWrapper>
            <Box marginBottom={2}>
              <Text variant="h3">
                <Trans>public.residency.business_benefits.title</Trans>
              </Text>
            </Box>
            <Box marginBottom={4}>
              <Text variant="p-lg">
                <Trans>public.residency.business_benefits.subtitle</Trans>
              </Text>
            </Box>
            <BusinessBenefitsGrid>
              <BusinessBenefitsItem>
                <AlertIcon variant="success" size="small" />
                <Text>
                  <Trans>public.residency.business_benefits.point_1</Trans>
                </Text>
              </BusinessBenefitsItem>
              <BusinessBenefitsItem>
                <AlertIcon variant="success" size="small" />
                <Text>
                  <Trans>public.residency.business_benefits.point_2</Trans>
                </Text>
              </BusinessBenefitsItem>
              <BusinessBenefitsItem>
                <AlertIcon variant="success" size="small" />
                <Text>
                  <Trans>public.residency.business_benefits.point_3</Trans>
                </Text>
              </BusinessBenefitsItem>
              <BusinessBenefitsItem>
                <AlertIcon variant="success" size="small" />
                <Text>
                  <Trans>public.residency.business_benefits.point_4</Trans>
                </Text>
              </BusinessBenefitsItem>
              <BusinessBenefitsItem>
                <AlertIcon variant="success" size="small" />
                <Text>
                  <Trans>public.residency.business_benefits.point_5</Trans>
                </Text>
              </BusinessBenefitsItem>
              <BusinessBenefitsItem>
                <AlertIcon variant="success" size="small" />
                <Text>
                  <Trans>public.residency.business_benefits.point_6</Trans>
                </Text>
              </BusinessBenefitsItem>
            </BusinessBenefitsGrid>
            <BusinessBenefitsDottedDividerWrapper>
              <DottedDivider />
            </BusinessBenefitsDottedDividerWrapper>
            <Box marginBottom={3}>
              <Text variant="h3">
                <Trans>public.residency.advantages.title</Trans>
              </Text>
            </Box>
            <Advantages>
              <Text variant="p-lg">
                <Trans>public.residency.advantages.innovative_platform</Trans>
              </Text>
              <Text variant="p-lg">
                <Trans>public.residency.advantages.easy_to_use</Trans>
              </Text>
            </Advantages>
            <LearnMoreButton>
              <Link to="/about" className="no-decoration">
                <Button fullWidth color="accent" variant="border" as="div">
                  <Trans>common.learn_more</Trans>
                </Button>
              </Link>
            </LearnMoreButton>
          </ContentWrapper>
        </AccentBlock>
        <Angles flipVertical flipHorizontal />
      </MainMenu>
    </>
  );
};

export default Residency;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
